import { render, staticRenderFns } from "./AccountVips.vue?vue&type=template&id=3e4a96af&"
import script from "./AccountVips.vue?vue&type=script&lang=js&"
export * from "./AccountVips.vue?vue&type=script&lang=js&"
import style0 from "./AccountVips.vue?vue&type=style&index=0&id=3e4a96af&prod&lang=scss&"
import style1 from "./AccountVips.vue?vue&type=style&index=1&id=3e4a96af&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
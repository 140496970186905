<template>
  <div>
    <b-card>
      <!-- Modal -->
      <!-- Header -->
      <b-row class="table-filter">
        <b-col cols="12">
          <h4 class="b-card-title">Top Deposit Accounts</h4>
        </b-col>

        <!-- Filter Active -->
        <b-col cols="2">
          <b-form-group id="input-top-filter">
            <b-form-select id="top-filter" v-model="top" :options="topList" @change="fetchTopDeposit" />
          </b-form-group>
        </b-col>
        <b-col cols="2" class="form-checkbox">
          <b-form-checkbox
            id="vip-filter"
            v-model="vip"
            :value="true"
            :unchecked-value="false"
            @change="fetchTopDeposit"
            >View VIP users</b-form-checkbox
          >
        </b-col>

        <b-col cols="4"></b-col>

        <b-col cols="4" class="btnAdd mb-3">
          <b-button variant="primary" class="float-right" @click="addItem">Add VIP user</b-button>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table-simple small responsive bordered class="elevation-1 data-table text-center">
        <b-thead>
          <b-tr>
            <b-th>Top</b-th>
            <b-th>Name</b-th>
            <b-th>Phone</b-th>
            <b-th>Date Of Birth</b-th>
            <b-th>Total Deposit</b-th>
            <b-th>Total Bought</b-th>
            <b-th>Total Balance</b-th>
            <b-th>Status</b-th>
            <b-th>Actions</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in topDeposit" :key="'row_' + index">
            <b-td>{{ index + 1 }}</b-td>
            <b-td>{{ item.name }}</b-td>
            <b-td>{{ item.username }}</b-td>
            <b-td>{{ item.date_of_birth }}</b-td>
            <b-td>{{ formatPrice(item.total_deposit) }}</b-td>
            <b-td>{{ formatPrice(item.total_paid) }}</b-td>
            <b-td>{{ formatPrice(item.total_balance) }}</b-td>
            <b-td>
              <b-badge class="mr-1" v-if="item.account_vip" variant="warning">VIP</b-badge>
              <b-badge v-if="item.account_shop" variant="info">SHOP</b-badge>
            </b-td>
            <b-td>
              <span class="action-btn mr-2" @click="viewDepositLog(item)" :id="'view_log_' + item.id">
                <i class="flaticon-signs-1 text-info icon-md"></i>
              </span>
              <b-tooltip :target="'view_log_' + item.id" triggers="hover">View deposit logs</b-tooltip>
              <span
                v-if="!item.account_vip && !isManager"
                class="action-btn"
                @click="setVip(item)"
                :id="'setVip_' + item.id"
                ><i class="flaticon-star text-warning icon-md"></i>
              </span>
              <b-tooltip v-if="!item.account_vip && !isManager" :target="'setVip_' + item.id" triggers="hover"
                >Set VIP user</b-tooltip
              >
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card>
      <b-modal v-model="modalLog" :title="modalLogTitle" size="xl" header-close-content hide-footer>
        <b-container fluid>
          <b-table-simple small responsive bordered class="elevation-1 data-table text-center">
            <b-thead>
              <b-tr>
                <b-th>Created At</b-th>
                <b-th>Deposit ID</b-th>
                <b-th>Method</b-th>
                <b-th>Total Payment</b-th>
                <b-th>Fee</b-th>
                <b-th>Commission</b-th>
                <b-th>Deposit Amount</b-th>
                <b-th>Status</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(item, index) in logs" :key="'row_' + index">
                <b-td>{{ item.created_at }}</b-td>
                <b-td>{{ item.display_deposit_id }}</b-td>
                <b-td
                  :variant="
                    item.method_handler == 'deposit_cms'
                      ? 'success'
                      : item.method_handler == 'revenue_cms'
                      ? 'primary'
                      : ''
                  "
                  >{{ item.method_name }}</b-td
                >
                <b-td>{{ formatPrice(item.total_payment) }}</b-td>
                <b-td>{{ formatPrice(item.fee) }}</b-td>
                <b-td :variant="item.commission > 0 ? 'success' : ''">{{ formatPrice(item.commission) }}</b-td>
                <b-td>{{ formatPrice(item.deposit_amount) }}</b-td>
                <b-td>
                  <b-badge class="mr-1" v-if="item.status == 1" variant="success">SUCCESS</b-badge>
                  <b-badge v-else-if="item.status == 2" variant="danger">FAILED</b-badge>
                  <b-badge v-else variant="warning">PENDING</b-badge>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-container>
      </b-modal>
      <b-modal
        v-model="modalVip"
        :title="modalVipTitle"
        ok-title="Save"
        size="xl"
        header-close-content
        @ok="saveVip"
        @cancel="closeVip"
      >
        <b-container fluid>
          <b-row class="vip-form">
            <div class="col-md-6 multi-select-user mb-3">
              <label>VIP user</label>
              <multiselect
                v-model="selectUser"
                :options="options"
                placeholder="Search user's name or phone..."
                :searchable="true"
                :loading="isSearching"
                :hide-selected="true"
                @search-change="searchDebounce"
                :show-no-results="true"
                :limit="searchLimit"
                :close-on-select="true"
                track-by="code"
                label="name"
                :disabled="selectDisabled"
              >
                <template slot="clear" slot-scope="props">
                  <div
                    class="multiselect__clear"
                    v-if="selectUser"
                    @mousedown.prevent.stop="clearAll(props.search)"
                  ></div>
                </template>
                <span slot="noResult">No user found</span>
              </multiselect>
            </div>
            <div class="col-md-3">
              <b-form-group id="input-valid-from" label="Valid From" label-for="valid-from">
                <datePicker placeholder="Valid From" v-model="validFrom" :config="dpOptions"></datePicker>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-checkbox
                id="checkbox-1"
                v-model="isShop"
                name="checkbox-1"
                size="lg"
                :value="true"
                :unchecked-value="false"
                >Shop</b-form-checkbox
              >
            </div>
            <div class="col-md-4" v-if="isShop">
              <b-form-group id="input-shop-name" label="Shop name" label-for="shop-name">
                <b-form-input id="shop-name" v-model="shopName" placeholder="Shop name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4" v-if="isShop">
              <b-form-group id="input-shop-phone" label="Shop phone" label-for="shop-phone">
                <b-form-input id="shop-phone" v-model="shopPhone" placeholder="Shop phone"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4" v-if="isShop">
              <b-form-group id="input-shop-address" label="Shop address" label-for="shop-address">
                <b-form-input id="shop-address" v-model="shopAddress" placeholder="Shop address"></b-form-input>
              </b-form-group>
            </div>
            <!-- status -->
            <b-col cols="3">
              <b-form-group
                id="input-deposit-commission-rate"
                label="Deposit Cashback Rate"
                label-for="deposit-commission-rate"
              >
                <b-input-group append="%">
                  <b-form-input
                    id="deposit-commission-rate"
                    v-model="deposit_commission_rate"
                    type="number"
                    placeholder="3"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                id="input-revenue-commission-rate"
                label="Revenue Commission Rate"
                label-for="revenue-commission-rate"
              >
                <b-input-group append="%">
                  <b-form-input
                    id="revenue-commission-rate"
                    v-model="revenue_commission_rate"
                    type="number"
                    placeholder="0"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                id="input-referral-commission-rate"
                label="F1 Bonus Rate"
                label-for="referral-commission-rate"
              >
                <b-input-group append="%">
                  <b-form-input
                    id="referral-commission-rate"
                    v-model="referral_commission_rate"
                    type="number"
                    placeholder="0"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group id="input-friend-commission-rate" label="F2 Bonus Rate" label-for="friend-commission-rate">
                <b-input-group append="%">
                  <b-form-input
                    id="friend-commission-rate"
                    v-model="friend_commission_rate"
                    type="number"
                    placeholder="0"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <!-- Header -->
      <b-row class="table-filter">
        <b-col cols="12">
          <h4 class="b-card-title">VIP Account List</h4>
        </b-col>

        <!-- Filter Phone -->
        <b-col cols="4">
          <b-form-group id="input-phone-filter" label="Name or phone" label-for="phone-filter">
            <b-form-input
              id="phone-filter"
              v-model="filter_phone"
              type="text"
              placeholder="Search name or phone number..."
              @input="onFilterName"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- Filter Active -->
        <b-col cols="2">
          <b-form-group id="input-status-filter" label="Status" label-for="status-filter">
            <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
              <template v-slot:first>
                <b-form-select-option :value="null">All</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <!-- <b-col cols="4" class="btnAdd">
        <b-button variant="primary" class="float-right" @click="addItem"
          >Add</b-button
        >
        </b-col>-->
      </b-row>
      <!-- Table -->
      <b-table-simple small responsive bordered class="elevation-1 data-table text-center">
        <b-thead>
          <b-tr>
            <b-th>Account</b-th>
            <b-th colspan="2">Deposit Cashback</b-th>
            <b-th colspan="2">Revenue Commission</b-th>
            <b-th>Total F1</b-th>
            <b-th colspan="2">F1 Bonus</b-th>
            <b-th>Total F2</b-th>
            <b-th colspan="2">F2 Bonus</b-th>
            <b-th>Status</b-th>
            <b-th v-if="!isManager">Actions</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-for="(item, index) in items" :key="'row_' + index">
          <b-tr>
            <b-td rowspan="3" v-if="item.name != ''">
              <strong>{{ item.name }}</strong>
              <br />
              <strong>{{ item.phone }}</strong>
              <br />
              {{ item.date_of_birth }}
            </b-td>
            <b-td rowspan="3" v-else>{{ item.phone }}</b-td>
            <b-th class="text-left">Rate</b-th>
            <b-td class="text-right">{{ item.deposit_cms_rate * 100 || 0 }}%</b-td>
            <b-th class="text-left">Rate</b-th>
            <b-td class="text-right">{{ item.revenue_cms_rate * 100 || 0 }}%</b-td>
            <b-td rowspan="3">{{ item.total_invited }}</b-td>
            <b-th class="text-left">Rate</b-th>
            <b-td class="text-right">{{ item.referral_cms_rate * 100 || 0 }}%</b-td>
            <b-td rowspan="3">{{ item.total_friend_invited }}</b-td>
            <b-th class="text-left">Rate</b-th>
            <b-td class="text-right">{{ item.friend_cms_rate * 100 || 0 }}%</b-td>
            <b-td rowspan="3">
              <b-badge v-if="item.status === 1" variant="success">Active</b-badge>
              <b-badge v-else-if="item.status === 0" variant="warning">Inactive</b-badge>
              <br /><b-badge class="mt-2" v-if="item.is_shop === 1" variant="primary">Shop</b-badge>
            </b-td>
            <b-td rowspan="3" v-if="!isManager">
              <span class="action-btn mr-2" @click="editCommission(item)" :id="'edit_rate_' + item.id"
                ><i class="flaticon-edit text-primary icon-md"></i>
              </span>
              <b-tooltip :target="'edit_rate_' + item.id" triggers="hover">Edit user VIP</b-tooltip>
              <span class="action-btn mr-2" @click="addCommission(item)" :id="'add_vip_cms_' + item.id">
                <i class="far fa-money-bill-alt text-success icon-md"></i>
              </span>
              <b-tooltip :target="'add_vip_cms_' + item.id" triggers="hover">Add cashback</b-tooltip>
              <span class="action-btn" @click="removeVip(item)" :id="'remove_vip_' + item.id">
                <i class="flaticon2-trash text-danger icon-md"></i>
              </span>
              <b-tooltip :target="'remove_vip_' + item.id" triggers="hover">Remove VIP user</b-tooltip>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-left">Total</b-th>
            <b-td class="text-right">{{ formatPrice(item.total_deposit || 0) }}</b-td>
            <b-th class="text-left">Total</b-th>
            <b-td class="text-right">{{ formatPrice(item.total_revenue || 0) }}</b-td>
            <b-th class="text-left">Total</b-th>
            <b-td class="text-right">{{ formatPrice(item.total_referral || 0) }}</b-td>
            <b-th class="text-left">Total</b-th>
            <b-td class="text-right">{{ formatPrice(item.total_friend_revenue || 0) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-left">Casback</b-th>
            <b-td class="text-right">{{ formatPrice(item.total_deposit_cms || 0) }}</b-td>
            <b-th class="text-left">Commission</b-th>
            <b-td class="text-right">{{ formatPrice(item.total_revenue_cms || 0) }}</b-td>
            <b-th class="text-left">Bonus</b-th>
            <b-td class="text-right">{{ formatPrice(item.total_referral_cms || 0) }}</b-td>
            <b-th class="text-left">Bonus</b-th>
            <b-td class="text-right">{{ formatPrice(item.total_friend_cms || 0) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <!-- Footer -->
      <b-row>
        <b-col cols="5">
          Showing {{ (currentPage - 1) * perPage + 1 }}-{{
            currentPage * perPage > rows ? rows : currentPage * perPage
          }}
          of {{ rows }}.
        </b-col>
        <b-col style="display: flex; justify-content: flex-end">
          <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
            <label class="mr-sm-2" for="per-page">Rows per page:</label>
            <b-form-select
              id="per-page"
              v-model="perPage"
              size="sm"
              :options="[5, 10, 15, 20]"
              @change="fetchData"
            ></b-form-select>
          </b-form>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            align="right"
            v-on:input="fetchData"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<style lang="scss">
.vip-form {
  .form-group {
    margin-bottom: 1rem;
  }
  .custom-checkbox {
    position: absolute;
    bottom: 1.5rem;
  }
}
.form-checkbox .custom-checkbox {
  bottom: 1.6rem;
  position: absolute;
}
.card + .card {
  margin-top: 2rem;
}
.table-filter {
  .form-group {
    margin-bottom: 1rem;
    label {
      margin-bottom: 0 !important;
    }
  }
}
.data-table {
  tbody {
    tr:first-child {
      td,
      th {
        border-top: none;
      }
    }
    td {
      vertical-align: middle;
    }
  }
}
.multi-select-user {
  label {
    font-size: 1rem;
    font-weight: 400;
  }
  .multiselect {
    min-height: 38px;
    &__tags {
      min-height: 38px;
      height: 38px;
      padding-top: 5px;
      input {
        margin-bottom: 0;
        vertical-align: middle;
      }
      border: 1px solid #e2e5ec;
    }
    &__single {
      margin-bottom: 0;
      margin-top: 3px;
      font-size: 14px;
    }
  }
}
</style>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import jwtService from '@/common/jwt.service';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import { formatDateV2 } from '@/utils/time.js';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Swal from 'sweetalert2';
export default {
  name: 'account-vips',
  components: { Multiselect, datePicker },
  data() {
    return {
      search: '',
      isBusy: false,
      filter_status: null,
      filter_phone: '',
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      // Modal data
      items: [],
      topDeposit: [],
      modalTitle: '',
      // Modal validate
      top: 20,
      topList: [
        { value: 5, text: 'Top 5' },
        { value: 10, text: 'Top 10' },
        { value: 20, text: 'Top 20' },
        { value: 50, text: 'Top 50' },
        { value: 100, text: 'Top 100' }
      ],
      rows: 0,
      perPage: 20,
      currentPage: 1,
      vip: true,
      modalVip: false,
      modalVipTitle: '',
      vipId: null,
      deposit_commission_rate: 0,
      revenue_commission_rate: 0,
      referral_commission_rate: 0,
      modalLog: false,
      modalLogTitle: '',
      logs: [],
      editting: false,
      metadata: {},
      receiver_info: {},
      save_receiver: '',
      dialog: false,
      dialogUpdate: false,
      editedIndex: -1,
      editorConfig: {
        language: 'de'
      },
      options: [],
      selectUser: null,
      isSearching: false,
      searchLimit: 20,
      searchOffset: 1,
      debounce: null,
      selectDisabled: false,
      isShop: false,
      shopName: '',
      shopPhone: '',
      shopAddress: '',
      validFrom: new Date(),
      friend_commission_rate: 0,
      dpOptions: {
        format: 'DD/MM/YYYY',
        sideBySide: true
      },
      isManager: false
    };
  },
  watch: {
    modalVip(val) {
      val || this.closeVip();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return this.errors.has('Status') || this.filter_status === null;
    }
  },
  created() {
    if (jwtService.getToken()) {
      const { role } = jwt_decode(jwtService.getToken());
      this.isManager = role === 'vipmanager';
    } else {
      this.$router.push('/');
    }
    this.fetchAll();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Referrals' }, { title: 'Account VIPs' }]);
  },
  methods: {
    addItem() {
      this.modalVip = true;
      this.modalVipTitle = `Add user VIP`;
      this.selectDisabled = false;
    },
    clearAll() {
      this.selectUser = null;
    },
    searchDebounce(query) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchUser(query);
      }, 500);
    },
    searchUser(query) {
      if (!query.length) return;
      this.isSearching = true;
      ApiService.get(`accounts`, `?q=${query}&limit=${this.searchLimit}&offset=${this.searchOffset}`)
        .then((resp) => {
          if (resp && resp.data.status) {
            this.options = resp.data.data.accountList.map((user) => ({
              name: user.name ? `${user.name} (${user.phone})` : user.phone,
              code: user.id
            }));
          }
        })
        .catch((err) =>
          Swal.fire({
            icon: 'error',
            title: `Error`,
            text: err,
            showConfirmButton: false,
            timer: 3000
          })
        )
        .finally(() => (this.isSearching = false));
    },
    editCommission(item) {
      this.modalVip = true;
      this.modalVipTitle = `Update user ${item.name}'s commission rate`;
      this.vipId = item.id;
      this.deposit_commission_rate = item.deposit_cms_rate * 100;
      this.revenue_commission_rate = item.revenue_cms_rate * 100;
      this.referral_commission_rate = item.referral_cms_rate * 100;
      this.friend_commission_rate = item.friend_cms_rate * 100;
      this.editting = true;
      this.selectUser = { name: item.name ? `${item.name} (${item.phone})` : item.phone, code: item.id };
      this.options = [{ name: item.name ? `${item.name} (${item.phone})` : item.phone, code: item.id }];
      this.selectDisabled = true;
      this.validFrom = new Date(item.valid_from || item.created_at);
      this.isShop = !!item.is_shop;
      this.shopName = item.shop_name;
      this.shopPhone = item.shop_phone;
      this.shopAddress = item.shop_address;
    },
    setVip(item) {
      this.modalVip = true;
      this.modalVipTitle = `Set user ${item.name} as VIP`;
      this.vipId = item.id;
      this.selectUser = { name: item.name ? `${item.name} (${item.phone})` : item.phone, code: item.id };
      this.options = [{ name: item.name ? `${item.name} (${item.phone})` : item.phone, code: item.id }];
      this.selectDisabled = true;
      this.validFrom = new Date();
      this.isShop = false;
    },
    removeVip(item) {
      Swal.fire({
        icon: 'warning',
        showCancelButton: true,
        title: `Are you sure to remove VIP status of user ${item.name}`,
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete(`account-vips/${item.id}`)
            .then((response) => {
              if (response.status == 204)
                Swal.fire({
                  icon: 'success',
                  title: `VIP status removed`,
                  showConfirmButton: false,
                  timer: 3000
                });
              else
                Swal.fire({
                  icon: 'error',
                  title: `Error`,
                  text: response.data.data.message,
                  showConfirmButton: false,
                  timer: 3000
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: `Error`,
                text: error,
                showConfirmButton: false,
                timer: 3000
              });
            })
            .finally(() => {
              this.fetchAll();
            });
        }
      });
    },
    addVipUser() {
      let validFrom = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
      if (this.validFrom) {
        if (typeof this.validFrom == 'string') {
          validFrom = new Date(this.validFrom.split('/').reverse().join('/')).toISOString();
        } else {
          validFrom = new Date(this.validFrom).toISOString();
        }
      }
      ApiService.post('account-vips', {
        account_id: this.selectUser.code,
        deposit_cms_rate: +this.deposit_commission_rate / 100,
        revenue_cms_rate: +this.revenue_commission_rate / 100,
        referral_cms_rate: +this.referral_commission_rate / 100,
        friend_cms_rate: +this.friend_commission_rate / 100,
        is_shop: this.isShop ? 1 : 0,
        valid_from: validFrom,
        shop_name: this.shopName || '',
        shop_phone: this.shopPhone || '',
        shop_address: this.shopAddress || ''
      })
        .then((response) => {
          if (response.status == 200)
            Swal.fire({
              icon: 'success',
              title: `User added as VIP`,
              showConfirmButton: false,
              timer: 3000
            });
          else
            Swal.fire({
              icon: 'error',
              title: `Error`,
              text: response.data.data.message,
              showConfirmButton: false,
              timer: 3000
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: `Error`,
            text: error,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.fetchAll();
        });
    },
    editVipUser() {
      let validFrom = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
      if (this.validFrom) {
        if (typeof this.validFrom == 'string') {
          validFrom = new Date(this.validFrom.split('/').reverse().join('/')).toISOString();
        } else {
          validFrom = new Date(this.validFrom).toISOString();
        }
      }
      ApiService.put(`account-vips/${this.vipId}`, {
        deposit_cms_rate: +this.deposit_commission_rate / 100,
        revenue_cms_rate: +this.revenue_commission_rate / 100,
        referral_cms_rate: +this.referral_commission_rate / 100,
        friend_cms_rate: +this.friend_commission_rate / 100,
        is_shop: this.isShop ? 1 : 0,
        valid_from: new Date(validFrom).toISOString(),
        shop_name: this.shopName || '',
        shop_phone: this.shopPhone || '',
        shop_address: this.shopAddress || ''
      })
        .then((response) => {
          if (response.status == 204)
            Swal.fire({
              icon: 'success',
              title: `User's commission rates updated`,
              showConfirmButton: false,
              timer: 3000
            });
          else
            Swal.fire({
              icon: 'error',
              title: `Error`,
              text: response.data.data.message,
              showConfirmButton: false,
              timer: 3000
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: `Error`,
            text: error,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(() => {
          this.fetchAll();
        });
    },
    saveVip() {
      if (this.editting) this.editVipUser();
      else this.addVipUser();
      this.editting = false;
    },
    closeVip() {
      this.modalVip = false;
      this.modalVipTitle = '';
      this.vipId = null;
      this.deposit_commission_rate = 3;
      this.revenue_commission_rate = 0;
      this.referral_commission_rate = 0;
      this.friend_commission_rate = 0;
      this.selectUser = null;
      this.options = [];
      this.selectDisabled = false;
      this.validFrom = new Date();
      this.isShop = false;
      this.shopName = '';
      this.shopPhone = '';
      this.shopAddress = '';
    },
    addCommission(item) {
      Swal.fire({
        title: `Add cashback to user ${item.name || item.username}`,
        input: 'number',
        showCancelButton: true,
        confirmButtonText: 'Add',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post('accounts/commissions', { account_id: item.id, amount: +result.value })
            .then((response) => {
              if (response.status == 204)
                Swal.fire({
                  icon: 'success',
                  title: `Added commission`,
                  showConfirmButton: false,
                  timer: 3000
                });
              else
                Swal.fire({
                  icon: 'error',
                  title: `Error`,
                  text: response.data.data.message,
                  showConfirmButton: false,
                  timer: 3000
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: `Error`,
                text: error,
                showConfirmButton: false,
                timer: 3000
              });
            })
            .finally(() => {
              this.fetchAll();
            });
        }
      });
    },
    viewDepositLog(item) {
      this.modalLog = true;
      this.modalLogTitle = `User ${item.name}'s deposit logs`;
      ApiService.get(`accounts/${item.id}/deposits`)
        .then((response) => {
          this.logs = response.data.data.depositList.map((deposit) => {
            return {
              ...deposit,
              created_at: formatDateV2(deposit.created_at)
            };
          });
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        });
    },
    fetchAll() {
      this.fetchTopDeposit();
      this.fetchData();
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    async fetchTopDeposit() {
      await this.$nextTick();
      let limit = this.top;
      ApiService.setHeader();
      ApiService.get('accounts/top-deposits', `?limit=${limit}&vip=${this.vip ? 1 : 0}`)
        .then((response) => {
          this.topDeposit = response.data.data.accountList.map((account) => {
            return {
              ...account,
              date_of_birth: formatDateV2(account.date_of_birth)
            };
          });
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        });
    },
    fetchData() {
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      ApiService.get(
        'account-vips',
        `?q=${this.filter_phone || ''}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&limit=${limit}&offset=${offset}`
      )
        .then((response) => {
          this.items = response.data.data.account_vips.map((account) => {
            return {
              ...account,
              id: account.account_id,
              date_of_birth: formatDateV2(account.date_of_birth)
            };
          });
          this.rows = response.data.data.total;
          this.isBusy = false;
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

<style lang="scss">
.metadata {
  font-size: 15px;
  color: #666666;
}
.form-row {
  .form-group {
    margin-bottom: 0;
  }
}
</style>
